import React, { ChangeEventHandler, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {
  Button,
  OutlinedInput,
  Typography,
  makeStyles,
  Slider,
  Paper,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
} from "@material-ui/core"
import ChipInput from "../components/ChipInput"

import axios from "axios"

import "./index.scss"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
    marginbottom: theme.spacing(2),
  },
  centerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  input: {
    width: "75%",
  },
  action: {
    width: "20%",
  },
  logo: {
    overflow: "auto",
    marginTop: "55px",
  },
}))

const Home = () => {
  const classes = useStyles()
  const [chips, setChips] = useState<string[]>([])
  const [price, setPrice] = useState([0, 10000])
  const [products, setProducts] = useState([])
  const [showTags, setShowTags] = useState(false)
  const [showPrice, setShowPrice] = useState(false)
  const [text, setText] = useState("")

  const marks = [
    {
      value: 0,
      label: "0 €",
    },
    {
      value: 100,
      label: "10000 €",
    },
  ]

  const handleAddChip = chip => setChips([...chips, chip])

  const handleDeleteChip = (_chip, index) => {
    chips.splice(index, 1)
    setChips(chips)
  }

  const handlePriceChange = (_event, price) => {
    setPrice(price)
  }

  const handleSearchClick = async _event => {
    const res = await axios.post(
      "https://us-central1-huskme.cloudfunctions.net/searchItems",
      { keywords: text.concat(" ", chips).replace(",", " ") }
    )

    const { data: products } = res

    setProducts(products)
  }

  const handleTextClick = () => setShowTags(true)

  const handleTagConfirm = () => setShowPrice(true)

  const handleChange: ChangeEventHandler<HTMLInputElement> = event =>
    setText(event.target.value)

  return (
    <Layout>
      <Seo title="HuskMe.com" />
      <StaticImage
        src="../images/huskme-logo.png"
        quality={95}
        alt="HuskMe logo"
        layout="fixed"
        style={{ position: "relative", top: "55px" }}
      />

      <Paper elevation={3} className={classes.paper}>
        <div className={classes.row}>
          <OutlinedInput
            label="Cosa stai cercando?"
            labelWidth={70}
            className={classes.input}
            value={text}
            onChange={handleChange}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.action}
            onClick={handleTextClick}
          >
            Conferma
          </Button>
        </div>

        {showTags && (
          <div className={classes.row}>
            <ChipInput
              value={chips}
              onAdd={chip => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
            />

            <Button
              variant="contained"
              color="primary"
              className={classes.action}
              onClick={handleTagConfirm}
            >
              Avanti
            </Button>
          </div>
        )}

        {showPrice && (
          <div className={classes.row}>
            <Typography
              id="range-slider"
              style={{ width: "100%" }}
              gutterBottom
            >
              Prezzo
            </Typography>
            <Slider
              value={price}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
              marks={marks}
              style={{ width: "75%" }}
            />
          </div>
        )}
      </Paper>

      {showPrice && (
        <div className={classes.centerRow}>
          <Button
            variant="contained"
            color="primary"
            className={classes.action}
            onClick={handleSearchClick}
            style={{ marginTop: "16px" }}
          >
            Cerca
          </Button>
        </div>
      )}

      {!!products?.length && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          {products.map(product => (
            <Card key={product.ASIN} className="product">
              <CardActionArea>
                <CardMedia
                  component="img"
                  image={product.Images.Primary.Large.URL}
                  title={product.ItemInfo.Title.DisplayValue}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {product.ItemInfo.Title.DisplayValue}
                  </Typography>
                  {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Lizards are a widespread group of squamate reptiles, with
                    over 6,000 species, ranging across all continents except
                    Antarctica
                  </Typography> */}
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  href={product.DetailPageURL}
                >
                  Visualizza
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      )}
    </Layout>
  )
}

export default Home
